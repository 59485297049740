body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #f7f7f7;
}

#root {
  background: #f7f7f7;
}

li,
th {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.5em;
}

::-webkit-scrollbar-track-piece {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #f7f7f7;
  border-radius: 8px;
}

.MuiDrawer-paperAnchorDockedLeft {
  z-index: -1 !important;
  margin-top: 65px !important;
}

#main-content-wrap {
  transition: 0.3s;
  background: #f7f7f7;
}

header {
  margin-bottom: 1em;
}

/* .MuiInputBase-input {
  padding: 7px !important;
  height: 30px !important;
  border-radius: 10px !important;
} */

.MuiButton-contained {
  border-radius: 6px !important;
}

label {
  line-height: 2 !important;
  font-size: 14px;
}

input[type='file']::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.mar-top {
  margin-top: 20px;
}

.manager-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user-request-info-type {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

input[type='file'] {
  color: rgba(0, 0, 0, 0);
  width: 95px;
  display: block;
}

.file-path-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 7px 0;
}

.file-path-wrap svg {
  margin-left: 10px;
  color: #c36e6e;
  cursor: pointer;
}

.file-path-wrap h6 {
  margin: 0;
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker-input {
  height: 44px !important;
  font-size: 1em !important;
}

.error-input {
  border: 1px solid #d32f2f !important;
  border-radius: '4px'
}

.text-danger {
  color: #d32f2f !important;
}

.p-16 {
  padding: 16px
}