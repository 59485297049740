@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Mulish:wght@300&family=Poppins:ital,wght@0,300;0,400;1,200&family=Roboto:wght@300&display=swap');
.card-title {
  font-size: 16px;
  color: #5a607f;
  font-family: 'Plus Jakarta Sans', sans-serif;
  opacity: 1;
  font-weight: 400;
}

.card-title p {
  margin: 0;
}

.card-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-count {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #131523;
  opacity: 0.53;
}
.card-data h3 {
  margin: 0;
}

.icon-wrap {
  height: 40px;
  width: 40px;
  background: #ccc;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-outer-wrap {
  padding: 0 5px;
}

.main-dashboard-wrap {
  width: 100;
  display: flex;
  margin-top: 20px;
  padding: 0 0;
  /* min-height: 600px; */
}

.left-content {
  width: 90%;
  /* margin-right: 2em; */
}

.active-dashboard {
  width: 100%;
}

.left-content-overview {
  display: flex;
  padding: 0 10px;
}
.link-icon-wrap img {
  width: 30px;
  height: 30px;
}
.month-selector {
  /* width: 10%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-data {
  text-align: center;
}

.month-data p,
h1 {
  margin: 0;
}

.month-data h1 {
  color: #c6c6c6;
}

.overview {
  width: 100%;
}

.right-content {
  width: 9%;
  height: 600px;
  transition: 0.3s;
}

.create-new-claim-links::-webkit-scrollbar {
  display: none;
}

.create-new-claim-links {
  height: 630px;
  overflow-y: scroll;
}

.create-new-claim-links h3 {
  color: #ffffff;
  text-align: center;
}

.link-wrap {
  /* padding: 5px 0; */
  text-align: -webkit-center;
  margin-bottom: 12px;
  cursor: pointer;
}

.link-title-wrap {
  text-align: center;
}

.link-title-wrap span {
  font-size: 11px;
  color: #ffffff;
  font-weight: bold;
  line-height: 10px;
}

.link-icon-wrap {
  padding: 11.5px;
  background: #474851;
  border-radius: 6px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs-main-wrap {
  padding: 10px;
}
.tab-data {
  background: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  overflow-y: scroll;
}

.div-count h2 {
  color: #131523;
}

.mobile-links {
  display: none !important;
}

.for-mobile {
  display: none;
}

.create-new-claim-links h3::after {
  content:'';
  margin-top: 10px;
  border-bottom: 2px dashed #ffffff;
  display: block;
}
.right-content .MuiCardContent-root {
  padding: 0;
}
@media (max-width: 1024px) {
  button.MuiButtonBase-root.MuiIconButton-root {
    padding: 0 0 0 2px;
  } 
  .tablecell-title-holder {
    font-size: 14px !important;

  }
}
@media (max-width: 480px) {
  .left-content {
    display: block;
    width: 100%;
  }

  .main-dashboard-wrap {
    display: block;
    padding: 0 10px;
  }

  .overview {
    width: 100%;
  }

  .card-outer-wrap {
    padding: 0;
    margin-right: 15px;
  }

  .right-content {
    margin: 10px 0;
    width: 100%;
  }

  .overview {
    display: flex;
    overflow-x: scroll;
  }

  .overview::-webkit-scrollbar { 
    width: 0 !important;
    display: none; 
  }

  .kpi-main-wrap {
    display: flex !important;
    /* flex-direction: column !important; */
  }

  .left-content-overview {
    max-height: 132px;
    padding: 0;
  }

  .card-outer-wrap {
    width: 250px;
  }

  .kpi-card {
    padding: 0;
    width: auto !important;
  }

  .tabs-main-wrap {
    padding: 10px 0;
  }

  .mobile-links {
    display: block !important;
    background-color: #32333c;
    width: fit-content;
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    bottom: -5px;
    right: -4px;
  }

  .mobile-links p {
    color: #ffffff;
    font-weight: 600;
  }

  .for-mobile {
    /* display: block !important; */
    width: 31%;
    position: fixed;
    right: -2px;
    top: calc(100% - 73%);
    transition: all 2s linear;
    z-index: 2;
  }

  .right-links-card {
    padding: 0;
  }

  .right-content{
    display: none;
    transition: 0.5s;
  }

  .tab-data {
    width: 100%;
    overflow-y: scroll;
  }

  .action-buttons{
    margin-left: 20px;
  }

  .table-options-wrap button,input{
    min-width: 100px !important;

  }

  .for-mobile .MuiCardContent-root{
    padding: 0;
  }

  .for-mobile h3::after {
    content:'';
    margin-top: 10px;
    border-bottom: 2px dashed #ffffff;
    display: block;
  }
}


#menu-select-claim-type {
  z-index: 1600;
}

/* For iPdads,Tablets */
@media only screen and (min-width: 481px) and (max-width: 768px){
 .left-content {
  width: 82%;
 }
 .right-content {
  width: 16%;
 }
 .active-dashboard {
  width: 100%;
 }
 .tab-data {
  width: 100%;
  overflow-y: scroll;
}
.action-buttons {
  margin-left: 20px;
 }
 .action-buttons input {
  min-width: 150px !important;
 }

 .action-buttons button {
  width: 140px !important;
 }
 .tab-data {
  width: 100%;
  overflow-y: scroll;
}
.active-dashboard{
  width: 100%;
}
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
  .left-content {
    width: 83%;
   }
   .right-content {
    width: 16%;
   }

   .action-buttons {
    margin-left: 20px;
   }

   .action-buttons input {
    min-width: 150px !important;
   }

   .action-buttons button {
    width: 120px !important;
   }

   .active-dashboard{
    width: 100%;
  }   
  .tab-data {
    width: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){
  .left-content {
    width: 88%;
   }
   .right-content {
    width: 11%;
   }
}