.modal {
  z-index: 1500;
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}
#modal-hook .modal-enter-done::-webkit-scrollbar{
  display: none;
}
#modal-hook .modal-enter-done{
  max-height: 80vh;
  overflow-x: auto;
  border-radius: 20px;
  padding: 0 !important;
  overflow-y: scroll;
}
.modal__header {
  width: auto;
  padding: 2em;
  background: #fcf1ea;
  color: #000000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 1em;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 2em;
}

.modal__footer {
  padding: 2em;
  text-align: end;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

@media (max-width: 576px) {
  .modal {
    right: 3%;
    left: 5%;
    width: 90%;
  }
  .modal__content,.modal__footer{
    padding: 2em !important;
  }
}
