.data-wrap{
    padding: 10px 0;
}

.data-title{
    color: #32333C;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 1em;

}

.data-subtitle{
    color: #707070;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
font-size: .9em;
}