.ve-date-range-cn {
    padding-top: 3px;
    overflow: hidden;
    transition: max-width 1s ease;
    max-width: 0;
    &.open {
        max-width: 100%
    }
}

