.login-image-container {
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.login-image-container img {
  height: 100%;
  width: 100%;
}

.login-inputs-container label {
  color: #671b1b;
  font-weight: 500;
}

.login-inputs-container h2 {
  text-align: center;
  width: 100%;
}

.login-btn {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  text-transform: capitalize !important;
}

.remember-me-label {
  font-size: 0.8em !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.forgot-password-wrap {
  text-align: center;
  margin: 1em;
}
.authentication {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #b5b9cc5d;
  opacity: 1;
  border-radius: 50%;
  padding: 0.5em;
  cursor: pointer;
}

@media (max-width: 576px) {
.login-screen-wrap{
  margin-top: 10%;
}
}

.client-logo img {
  width: 50%;
}