.audit-trail-wrap-upper {
  padding: 0 !important;
}

.audit-trail-wrap {
  background: #ffffff;
  border-radius: 10px;
  /* margin-top: 50px; */
  padding: 0 !important;
}

.audit-summary-wrap {
  background-color: #fcf1ea;
  border-top-left-radius: 10px;
  padding: 20px;
}

.audit-trail-wrap h4 {
  text-decoration: underline;
  margin-bottom: 15px;
}

.audit-summary-wrap h4 {
  margin-bottom: 15px;
}

.audit-summary-wrap p {
  margin: 5px 0 !important;
  color: #707070;
  font-size: 0.9em;
}

.summary-details {
  margin: 20px 0;
}

.summary-details p:nth-child(1) {
  font-size: 0.9em;
}

.summary-details p:nth-child(2) {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 0.9em;
}

.audit-claim-details-wrap {
  padding: 20px;
}

.audit-trail-wrap-inner {
  background-color: #ffffff9a;
  padding: 20px;
}

.audit-trail-wrap-inner h3 {
  text-decoration: underline;
}

/* Stepper css */

.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: white;
  border: 1px solid gray;
  border-radius: 100%;
  width: 20px; /* +6 for border */
  height: 20px;
  display: flex;
}

.step .line {
  top: 23px;
  left: 10px;
  /*   height: 120px; */
  height: 94%;

  position: absolute;
  border-left: 1px solid #707070;
}

.step.completed .circle {
  visibility: visible;
  background-color: #00ac94;
  border-color: #00ac94;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.step.completed .line {
  border-left: 1px solid #707070;
}

.step.active .circle {
  visibility: visible;
  border-color: rgb(6, 150, 215);
  margin-top: 1px;
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 1px solid white;
  z-index: -1; /* behind the circle to completely hide */
}

.content {
  display: inline-block;
  background-color: #0b87550d;
  padding: 15px;
  width: 50%;
  border-radius: 10px;
  margin: -20px 0 20px 20px;
}

.not-active {
  border-left: 1px solid gray !important;
  background-color: gray !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  display: flex;
  justify-content: flex-end;
}

.status span {
  border-radius: 6px;
  font-size: 0.6em;
  padding: 3px 10px;
  background-color: #fff;
  font-weight: 600;
}

.success {
  color: #00ac94;
}

.danger {
  color: #ee7979;
}

.step-name h5 {
  margin: 0;
}

.step-name span {
  font-size: 0.6em;
  color: #707070;
  text-transform: capitalize;
}

.step-send-back-response ul {
  margin: 0;
  padding-left: 15px;
  list-style: none;
}

.step-send-back-response h5 {
  margin-bottom: 5px;
}

.step-send-back-response li {
  font-size: 0.7em;
  padding: 2px 0;
}
.step-send-back-response li::before {
  content: '\2022';
  color: #707070;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.circle span {
  font-size: 0.8em;
  font-weight: 600;
}

.audit-trail-wrap-inner h4 {
  margin-bottom: 25px;
}

.claim-detail-title{
  font-weight: 600;
  font-size: .8em;
  text-transform: capitalize;
  color: #32333C;
}


.claim-detail-value {
  font-size: .8em;
  color: #707070;
  margin: 0;
}

.claim-attachment-link{
  font-size: .8em;
  color: #707070;
  text-decoration: none;
}

.not-completed {
  visibility: visible;
  /* background-color: #00ac94; */
  border-color: #00ac94;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  color: #ffffff;
}

.not-completed .circle {
  background-color: #00ac94;
  opacity: 0.5;
  border: 1px solid #00ac94;
  display: flex;
  justify-content: center;
}

.not-completed .content {
  opacity: 0.5;
}

.not-completed h5 {
  color: #282E45;
}

.not-completed .success {
  color: #32333C;
}

.single-claim-detail{
  margin-bottom: 1em;
}

.single-claim-detail p {
  margin: 5px 0;
}

/* For Mobile devices */
@media (max-width: 480px) {
  .audit-trail-wrap-upper {
    padding: 10px 0 0 10px !important;
  }

  .audit-trail-wrap .content{
    width: 100%;
  }
}


/* For iPdads,Tablets */
@media only screen and (min-width: 481px) and (max-width: 768px){
  .audit-trail-wrap-upper {
    padding: 10px 0 0 10px !important;
  }

  .audit-trail-wrap .content{
    width: 100%;
  }
}
/* For Small screens, laptops */
@media only screen and (min-width: 769px) and (max-width: 1024px){
  .audit-trail-wrap-upper {
    padding: 10px 0 0 10px !important;
  }

  .audit-trail-wrap .content{
    width: 100%;
  }
}