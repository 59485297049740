.search-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 24px;
  opacity: 0.4;
  padding: 10px 20px;
  min-width: 250px !important;
}

.table-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #282e45;
  text-transform: uppercase;
  opacity: 1;
}

.table-cell {
  text-align: left;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  letter-spacing: 0px;
  color: #282e45;
  opacity: 0.7;
  padding: 10px 16px !important;
}

.table-data:hover {
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000017;
  border-radius: 11px;
  opacity: 1;
}

.table-heading {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-size: 14px;
}

.my-custom-table img {
  width: 8px;
}

.table-options-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-table-body td {
  border-bottom: none;
}

.action-buttons {
  display: flex;
  align-items: center;
}
.tablecell-title-holder,
.tablecell-title-active {
  font-weight: 800 !important;
}
.tablecell-title-holder svg,
.tablecell-title-active svg {
  color: black;
  font-size: 14px !important;
}
.tablecell-title-active svg {
  transform: rotate(180deg);
}


@media (max-width: 1250px) {
  .responsive_table {
    overflow: auto;
  }
  table#myTable {
    width: 1200px !important;
  }
  .left-content.active-dashboard {
    width: 100% !important;
  }
}
@media (max-width: 1024px) {
  .overview {
    overflow-x: scroll;
  }
  .kpi-main-wrap {
    width: 840px !important;
    flex-basis: inherit !important;
    max-width: inherit !important;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
  .card-outer-wrap {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .nav-main {
    overflow: auto;
  }
  .nav-main > div:last-child {
    width: 100px;
  }
  .nav-main-left-wrap {
    width: 450px;
  }
  .left-content.active-dashboard {
    width: 100% !important;
  }
  .outer-circle-wrap {
    margin-right: 5px !important;
  }
  .MuiSelect-root {
    font-size: 14px !important;
    padding-left: 0 !important;
  }
  .left-content {
    width: 80% !important;
  }
  .right-content {
    width: 20% !important;
  }
}
@media (max-width: 480px) {
  .left-content {
    width: 100% !important;
  }
  .right-content {
    width: 20% !important;
  }
}
