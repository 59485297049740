.footer-main {
  background-color: #f7f7f7;
  border-top-right-radius: 35px;
  border-top-right-radius: 35px;
  height: 60px;
  position: fixed;
  bottom: -2%;
  width: 79%;
  padding: 0.5em;
}

.footer-outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* For iPdads,Tablets */
@media only screen and (min-width: 1200px) and (max-width: 2000px) {
  .footer-main {
    width: 1020px !important;
  }
}
@media (max-width: 700px) {
  .footer-main {
    width: 100% !important;
    position: relative;
  }
  .footer-outer {
    flex-direction: column;
    padding-bottom: 30px;
    align-items: flex-start;
  }
  .footer-buttons {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  .footer-buttons button {
    font-size: 0.7em !important;
  }
  .delete-button {
    margin-left: -10px !important;
  }

  .form-main {
    margin-bottom: 0 !important;
  }
  .footer-container {
    padding: 0 !important;
  }

  .footer-form-name {
    padding: 10px 0;
  }

  .form-main-actions {
    justify-content: flex-start !important;
  }
}

@media only screen and (min-width: 100px) and (max-width: 650px) {
  .footer-main {
    width: 75%;
  }
}
