/* General container styling */
.form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Section styling */
  .section {
    margin-bottom: 20px;
  }
  
  /* Buttons */
  button {
    padding: 10px 15px;
    margin: 10px 0;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  /* button:hover {
    background: #0056b3;
  } */

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .submit-button, .discard-button {
    flex: 1;
    padding: 10px;
    margin: 0 5px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .discard-button {
    background-color: #dc3545;
    color: white;
  }
  
  .discard-button:hover {
    background-color: #c82333;
  }
  
  
  /* Error message */
  .error {
    color: red;
    font-size: 14px;
  }
  
  /* Camera and selfie styling */
  .camera-container {
    text-align: center;
    margin-top: 15px;
  }
  
  .video {
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .selfie-image {
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
  }
  
  .capture-button {
    display: block;
    margin: 10px auto;
    width: 30%;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .video, .selfie-image {
    width: 200px;
    height: 200px;
    border-radius: 50%; /* Makes it circular */
    object-fit: cover; /* Ensures the image/video fits properly */
    border: 3px solid #4CAF50; /* Optional: Add a border */
  }
  
  .camera-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .capture-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .capture-button:hover {
    background-color: #0056b3;
  }
  
  .selfie-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .form-container {
      padding: 15px;
    }
  
    button {
      font-size: 12px;
      padding: 8px 10px;
    }
  
    .video,
    .selfie-image {
      width: 90%;
    }
  }
  