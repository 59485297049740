.toggle-switch-wrap {
    margin-left: 15px;
    display: flex;
  }
  
  .outer-circle-wrap {
    height: 30px;
    width: 30px;
    background: #00ac94;
    border-radius: 50%;
    transition: 0.3s;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
  }
  
  .outer-circle-wrap span {
    display: block;
    font-size: 12px;
    padding-right: 8px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-weight: bold;
  }
  
  
  .inner-circle-wrap {
    position: absolute;
    background: #ffffff;
    color:#000000;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  
  .active-user {
    width: auto;
    height: 30px;
    border-radius: 34px;
    padding: 0 0 0 35px;

  }

  .active-circle {
      left:16px;
  }