.header-wrap{
  border-radius: 30px;
  overflow: hidden;
}

.nav-main {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
}

.appbar-wrap {
  border-radius: 30px;
  width: 99% !important;
  right: 0 !important;
  left: 0 !important;
  overflow: hidden;
  transform: translate(10px,10px) !important;
}

.nav-main-left-wrap {
  display: flex;
  align-items: center;
}

.nav-main-left-wrap h5 {
  color: #000;
}

.MuiPaper-elevation4 {
  box-shadow: 0 0 16px #ebebeb !important;
}
.test,.current-user-wrap {
  text-transform: capitalize;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}
.test::before,
.test::after {
  border-bottom: none !important;
}

.test li {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.nav-main-left-wrap a {
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.test .MuiSelect-icon{
  right: -15px !important;
  top: calc(50% - 17px);
}

.logo-wrap img {
margin-top: 2px;
  width: auto;
  height: 30px;
  margin-right: 10px;
}

.test .MuiSelect-selectMenu{
  text-overflow: unset;
}

@media (max-width: 480px) {
  .appbar-wrap {
    width: 95% !important;
  }
}