.card-view-two {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.claim-type,
.claim-ref,
.claim-amt,
.claim-dest {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.claim-amt {
  font-weight: bold !important;
  color: #282e45;
}

.claim-ref {
  color: #707070;
  flex-direction: column !important;
}

.claim-type {
  font-weight: bold !important;
}
.card-data-type {
  margin-top: 1em;
}

.form-heading-wrap{
  background: #FCF1EA !important;
  border-radius: 10px 10px 0 0 !important;
  box-shadow: 0px -10px 10px -10px #ebebeb !important;

}

.form-content-wrap {
  border-radius: 0 0 10px 10px !important;
  box-shadow: 0px 3px 8px #ebebeb !important;
}