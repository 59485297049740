.success-card-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-info {
    display: flex;
    height: 90px;
    margin-top: 25px;
  }
  .user-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .user-name {
    display: flex;
  }
  .user-info-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.7em;
  }
  .success-card-wrapper {
    display: flex;
    align-self: center;
    justify-self: center;
    flex-direction: column;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000017;
    opacity: 1;
    border-radius: 15px;
    margin-bottom: 70px;
  }
  
  .user-request-info {
    background: #fcf1ea 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 93px;
    display: flex;
    flex-direction: row;
  }
  
  .user-request-info-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .user-request-info-reference {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
  }
  .user-request-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
  }
  .user-status-paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
    border: none;
    box-shadow: none !important;
  }
  
  .data-heading {
    margin: 20px 0 0 30px;
    text-align: left;
    text-decoration: underline;
    font: normal normal 600 18px/23px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
  }
  
  .data-display {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    margin-top: 20px;
  }
  
  .data-title {
    text-align: left;
    font: normal normal normal 19px/24px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #32333c;
    opacity: 1;
  }
  
  .data-subtitle {
    text-align: left;
    font: normal normal normal 17px/22px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
  }
  
  .vertical-bar {
    height: 198px;
    width: 4px;
    border: 1px solid #ffffff;
    opacity: 0.5;
    background-color: #c2c7c7;
    margin-right: 30px;
  }
  .horizontal-bar {
    width: 90%;
    margin-left: 40px;
    margin-top: 10px;
    border-top: 1px dotted;
  }
  
  .bottom {
    background-color: #f7f7f7;
    height: 70px;
    width: 76%;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .bottom-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-left: 500px;
    text-align: center;
    font: normal normal bold 12px/15px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #777b81;
    opacity: 1;
  }
  
  .chain {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
    background: #dbf3f0 0% 0% no-repeat padding-box;
    border-radius: 20px;
    /* opacity: 0.14; */
    height: 30px;
  }
  
  .chain-data {
    display: flex;
    flex-direction: row;
    font: normal normal normal 18px/23px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #282e45;
  }
  
  .ids-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
  }
  
  .emp-id-wrap{
  padding-bottom: 3px;
  border-bottom: 1px dashed #707070;
  }
  
  .ref-number-wrap span{
  font-size: 12px;
  color: #707070;
  }
  
  .request-details-wrap {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
  }
  
  .request-info h5,h4 {
      margin: 0 0 5px 0;
  }
  
  .request-info h5{
      color: #707070;
  }
  
  .MuiFormControlLabel-label {
      font-size: 1em;
      color: #4A4A4A;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  
  }
  
  .modal-content, .footer-content {
      padding: 2em 4em;
  }
  