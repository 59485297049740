.user-name,
.user-value {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.user-value {
  font-weight: bold !important;
  text-transform: capitalize;
  padding: '0 10px';
}

.sub-details {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 0.8em;
  color: #282e45;
  margin-bottom: 5px;
}

.sub-details-value {
  font-size: 1em;
  color: #707070;
  margin-top: 0;
}

.claim-type-wrap {
  text-transform: capitalize;
}

.std-hr {
  border: 0.1px solid #cccccc !important;
  opacity: 35%;
}

.user-details-wrap-for-view {
  background-color: #ffffff;
  display: flex !important;
  justify-content: center !important;
  box-shadow: 0px 3px 16px #00000017;
  margin-bottom: 20px;
}

.inner-grid {
  justify-content: flex-start;
}

.user-detail-card {
  margin-top: 1em;
}

@media (max-width: 480px) {
  .user-details-wrap-for-view {
    justify-content: flex-start !important;
  }
}
