.ve-tab-head {
    &.selected {
        background-color: #00806E;
    }
}

.ve-tab-label {
    &.selected {
        color: #fff;
        font-weight: 400 !important;
    }
}