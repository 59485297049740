.success-card-main {
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-info{
    display: flex;
    height: 90px;
    margin-top: 25px;
    padding-left: 15px;
}
.user-details{
    display: flex;
    align-items: center;
    justify-content:flex-start;
}
.user-name{
    display: flex;
}
.user-info-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
}
.success-card-wrapper {
    display: flex;
    align-self: center;
    justify-self: center;
    flex-direction: column;
    height: 530px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000017;
    opacity: 1;
    border-radius: 15px;
}

.user-request-info {
    background: #FCF1EA 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 93px;
    display: flex;
    flex-direction: row;
}

.user-request-info-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.success-card-wrapper h6, p {
  font-family: 'Plus Jakarta Sans', sans-serif !important;

}

.user-request-info-reference {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-right: 20px;
}
.user-request-status{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
}
.user-status-paper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
    border: none;
    box-shadow: none !important;
}

.user-request-info {
    padding: 20px;
}

.success-card-wrapper {
    margin-top: 20px;
}


@media (max-width: 576px) {
    .success-card-main {
        /* padding: 15px;
        margin-top: 10%; */
    }

    .user-info {
        padding: 10px;
        margin-top: 0 !important;
    }

    .user-request-info {
        padding: 30px 0 !important;
    }

    .user-request-info {
        flex-direction: column !important;
        height: auto !important;
    }

    .user-request-info-reference{
        padding-left: 13px;
        margin-top: 20px;
        align-items: flex-start !important;
    }
}