.main-nav {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.menu-list-wrap ul li{
 font-size: 14px;
}

.main-menu-list {
  list-style: none;
  padding-left: 0;
}

.sub-menu-list {
  list-style: none;
  transition     : 0.3s;

}

.sub-menu-list  li{
 padding: 5px 0;
 font-weight: 600;

}

.sub-menu-list li span {
  padding-left: 10px;
  font-weight: 600;
}

.sub-menu-list li:hover{
  background-color: #e5e6e7;
  border-radius: 50px;
  cursor: pointer;
}



.menu-icon-rotate {
  transform:rotate(90deg);
  transition     : 0.3s;
}

.ve-nav-sublist {
  list-style: none;
  max-height: 0;
  transition: all 0.5s;
  overflow: hidden;
  &.showSublist {
    max-height: 100vh;
    padding-top: 3px;
  }
  
}
.ve-nav-sublist li{
  margin-bottom: 8px;
}
