#sidenav {
    height          : 100%;
    width           : 0;
    position        : fixed;
    z-index         : 0;
    top             : 0;
    left            : 0;
    background-color: #fff;
    overflow-x      : hidden;
    transition      : 0.3s;
}

#sidenav a {
    text-decoration: none;
    color: #000000;
}

#sidenav .closebtn {
    position   : absolute;
    top        : 0;
    right      : 25px;
    font-size  : 36px;
    margin-left: 50px;
}

#main {
    transition: margin-left .5s;
    padding   : 16px;
}

.menu-list-wrap {
    margin-top: 75px;
}

@media screen and (max-height: 450px) {
    #sidenav {
        padding-top: 15px;
    }

    #sidenav a {
        font-size: 18px;
    }
}
